import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { PurchaseService } from '../../purchase.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CodemastCRUDDialog } from 'src/app/Views/general/codemast/codemast.component';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-imp-pro',
  templateUrl: './edit-imp-pro.component.html',
  styleUrls: ['./edit-imp-pro.component.css']
})
export class EditImpProComponent implements OnInit {

  loading: boolean = false;
  repmodel: any = {};
  PrdGroup: any = [];
  PrdGroupList: any =[];
  DataForm: FormGroup;
  filteredOptions: any = [];
  filteredOptions2: any = [];
  PrdFilteredOpt: any = [];
  initialValues : any [];
  PartyList: any = [];
  AllProducts: any = [];
  submitted: boolean;
  ProdFamily: any = [];
  
  constructor(private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private PurchaseService: PurchaseService,
              private NotificationService: NotificationService,
              private commonservice: CommonService,
              private dialog: MatDialog,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,) { }

  ngOnInit() {
    this.GetPartyList();        
    this.GetPrdFam();    
    this.GetPrdGroup();    
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;    
    console.log(this.ReceivedData.data);
    
    this.DataForm.get('Party_name').valueChanges.subscribe(response => {
      this._filter(response);
    });
    this.DataForm.get('Product_nm').valueChanges.subscribe(response => {      
      this._Prdfilter(response);
    });
    if(this.ReceivedData.data.Product_id > 0){
      // console.log(this.ReceivedData.data.Haveapproval.trim());      
      // this.DataForm.get("TradeAgree").patchValue(this.ReceivedData.data.TradeAgree ? "Y" : "N");
      console.log(this.ReceivedData.data.HaveApproval);
      // this.DataForm.get("Party_name").patchValue(this.ReceivedData.data.Party_name);
      this.DataForm.get("HaveApproval").patchValue(this.ReceivedData.data.HaveApproval);
      this.DataForm.get("Trdagrdesc").patchValue(this.ReceivedData.data.Trdagrdesc.trim());
      this.DataForm.get("Compliancedesc").patchValue(this.ReceivedData.data.Compliancedesc);
      this.UpdImpoItem();      
    }
  }
  SaveData(){
    console.log(this.DataForm.value);
    this.submitted = true;
    if(!this.DataForm.valid) {
      return;
    }    
    this.PartyList.map(d => {
      if(d.nm.trim() === this.DataForm.get("Party_name").value){
        this.DataForm.get("Party_id").patchValue(d.id);
      }
    });    
    let hcode = this.DataForm.get("HsnCode").value;
    this.DataForm.get("HsnCode").patchValue(hcode.trim());
    console.log(this.DataForm.value);
    this.PurchaseService.EditImpoPrd(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.NotificationService.success("Data Saved Sucessfully... ");
        this.ResetData(); 
        window.location.reload();
      });    
  }  
  
  GetPrdGroup() {
    this._cservice.PrdGroupList() // 2. api/PrdGroupsAPI/GetPrdGroup -- API For --Get Product Group
      .subscribe(data => {        
        this.PrdGroup = [];
        this.PrdGroupList = data;
        this.PrdGroupList.map(p => {          
          if (p.grp_id === 2002) {
            this.PrdGroup.push(p);
          }
        });        
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");          
        });    
  }
  UpdImpoItem(){        
    this.PurchaseService.ImpEditItem(this.ReceivedData.data.Product_id)
    .subscribe((data: any) => {    
      console.log(data);       
      this.DataForm.patchValue(data);
      // data.map(d => {        
          this.DataForm.get("Party_name").patchValue(data.Party_nm);                                        
      // });      
    }, err => {
      console.log(err);
      this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");          
    }); 
  }
  GetAllImpPrd(){
    this.AllProducts = [];
    this.PurchaseService.GetAllImpPrd()
      .subscribe((data: any) => {        
        this.AllProducts = data;
        this.AllProducts = this.AllProducts.map((x) => {
          return {
            nm: x.Product_nm.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Product_id
          }           
        });        
        this._filter("");
      });      
  }
  
  GetPrdFam() {
    this.loading = true;
    this.commonservice.CodeList('ImpPrdGrp')
      .subscribe(
        (data: any) => {
          this.ProdFamily = JSON.parse(data);            
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  GetPartyList() {
    this.loading = true;
    this.PartyList = [];
    let data: any = {};    
    data.Firmx = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatas";
    this._http.post(url, null, this.repmodel)
      .subscribe((data1: any) => {        
        this.loading = false;        
        this.PartyList = data1;        
        this.PartyList = this.PartyList.map((x) => {
          return {
            nm: x.Party_name.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Party_id,            
          }          
        });
        this._filter("");          
      }, err => {
        console.log(err);
      });
  }
  CreateFormGroup() {
    return this.fb.group({
      Product_id: new FormControl(this.ReceivedData.data.Product_id),
      Party_id: new FormControl(0),
      Prodgrp_id: new FormControl('2002'),
      Prod_fam: new FormControl(804,Validators.required),
      Category: new FormControl(''),
      Product_nm: new FormControl('',Validators.required),
      HaveApproval: new FormControl(''),            
      Model: new FormControl(''),
      Party_name: new FormControl(''),
      HsnCode: new FormControl('',Validators.required),
      TradeAgree: new FormControl('true'),
      Trdagrdesc: new FormControl(''),
      Agr_Elaborate: new FormControl(''),
      Compliance: new FormControl('true'),
      Compliancedesc: new FormControl(''),            
    });
  }  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }  
  openDialog(): void {
    // Open Dialog
   
    const dialogRef = this.dialog.open(CodemastCRUDDialog, {
      width: '900px',
      height: '700px',
      data: {id: 37}        
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    //this.animal = result;
  });
}
  ResetData() {    
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
  }
  private _Prdfilter(value: string) {    
    if (!value)
    {      
      this.filteredOptions = [];
      return;
    }
    if (value.length < 1) {      
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();    
    this.filteredOptions = this.AllProducts.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
  private _filter(value: string) {
    if (!value)
    {
      this.filteredOptions2 = [];
      return;
    }
    if (value.length < 1) {
      this.filteredOptions2 = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions2 = this.PartyList.filter(option => option.nm.toLowerCase().includes(filterValue));
  }

}
