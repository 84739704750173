import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenIndComponent } from './gen-ind/gen-ind.component';
import { PurchaseRoutes } from './purchase.routing';
import { RouterModule } from '@angular/router';
import { PoComponent } from './po/po.component';
import { GatInwComponent } from './gat-inw/gat-inw.component';
import { GrnComponent } from './grn/grn.component';
import { PurRetComponent } from './pur-ret/pur-ret.component';
import { PrchkListComponent } from './prchk-list/prchk-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
//import { MatTableExporterModule } from 'mat-table-exporter';
import { MatFormFieldModule, MatBadgeModule } from '@angular/material';
import { MatInputModule, MatIconModule, MatCardModule, MatPaginatorModule,MatMenuModule, MatProgressBarModule, MatButtonModule, MatListModule, MatSidenavModule, MatGridListModule, MatTooltipModule, MatToolbarModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatExpansionModule, MatDialogModule, MatTabsModule, MatTableModule, MatSortModule, MatRadioModule ,MatSlideToggleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import { PurdialogComponent } from './prchk-list/purdialog/purdialog.component';
import { IndentListComponent } from './indent-list/indent-list.component';
import { IndentDialogComponent } from './indent-list/indent-dialog/indent-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { IndenterCrudComponent } from './indenter-crud/indenter-crud.component';
import { IndenterListComponent } from './indenter-list/indenter-list.component';
import {MatDividerModule} from '@angular/material/divider';
import { PoregComponent } from './poreg/poreg.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IndentComponent } from './indent/indent.component';
import { GrnRcvdComponent } from './grn-rcvd/grn-rcvd.component';
import { IndentRegComponent } from './indent-reg/indent-reg.component';
import { PoGenComponent } from './po-gen/po-gen.component';
import { JobworkOutComponent } from './jobwork-out/jobwork-out.component';
import { JobworkInwardComponent } from './jobwork-inward/jobwork-inward.component';
import { PoItemComponent } from './po-gen/po-item/po-item.component';
import { IndentApprovedComponent } from './po-gen/indent-approved/indent-approved.component';
import { PoPartyListComponent } from './po-gen/po-party-list/po-party-list.component';
import { ImpoPartyComponent } from './po-gen/impo-party/impo-party.component';
import { ImpoItemsComponent } from './po-gen/impo-items/impo-items.component';
import { PoManComponent } from './po-man/po-man.component';
import { PoListComponent } from './po-gen/po-list/po-list.component';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PdfListComponent } from './po-gen/pdf-list/pdf-list.component';
import { ImpProdFamilyComponent } from './po-gen/imp-prod-family/imp-prod-family.component';
import { ImpPrdListComponent } from './po-gen/imp-prd-list/imp-prd-list.component';
import { EditImpProComponent } from './po-gen/edit-imp-pro/edit-imp-pro.component';
import { ImpGrnComponent } from './imp-grn/imp-grn.component';
import { AddItemsGrnComponent } from './add-items-grn/add-items-grn.component';
import { GrnItems } from './imp-grn/imp-grn.component';
import { RepositoryComponent } from './po-gen/repository/repository.component';
import { PoPaymentsComponent } from './po-gen/po-payments/po-payments.component';

const Primeng = [
  TableModule,
]

const Material = [
  MatButtonModule,
  MatTableModule,
  MatChipsModule,
  MatIconModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatRadioModule,
  MatBadgeModule,
  MatMenuModule,
  MatDividerModule,
  MatSelectModule,
  MatTabsModule,
  MatDialogModule,
  MatToolbarModule,
  MatTooltipModule
]
@NgModule({
  declarations: [GenIndComponent, PoComponent, GrnItems, GatInwComponent, GrnComponent, PurRetComponent, PrchkListComponent, PurdialogComponent, IndentListComponent, IndentDialogComponent, IndenterCrudComponent, IndenterListComponent, PoregComponent, IndentComponent, GrnRcvdComponent, IndentRegComponent, PoGenComponent, JobworkOutComponent, JobworkInwardComponent, PoItemComponent, IndentApprovedComponent, PoPartyListComponent, ImpoPartyComponent, ImpoItemsComponent, PoManComponent, PoListComponent, PdfListComponent, ImpProdFamilyComponent, ImpPrdListComponent, EditImpProComponent, ImpGrnComponent, AddItemsGrnComponent, RepositoryComponent, PoPaymentsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule,
    Material,
    Primeng,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    Ng2SearchPipeModule,
    //MatTableExporterModule,
    MatFormFieldModule,
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    RouterModule.forChild(PurchaseRoutes)
  ],
  providers: [
    
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'en-IN'},
//    { provide: DateAdapter, useClass: AppDateAdapter },
  ],
  entryComponents: [
    PurdialogComponent,
    IndentDialogComponent,
    IndenterCrudComponent,
    PoItemComponent,
    IndentApprovedComponent,
    PoGenComponent,
    PdfListComponent,
    EditImpProComponent,
    AddItemsGrnComponent,
    GrnItems,
    PoPaymentsComponent
  ]
})
export class PurchaseModule { }
