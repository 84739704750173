import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-po-payments',
  templateUrl: './po-payments.component.html',
  styleUrls: ['./po-payments.component.css']
})
export class PoPaymentsComponent implements OnInit {

  user: User = {
    Pono: "Change",    
    Party_id: null,
    Amount: null,
    Tdate: new Date(),
  };
  
  constructor() { }

  ngOnInit() {
  }

  // AddItems() {
  //     (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
  // }

  submit() {
    alert(`Submited with: ${this.user.Pono}`);

    console.log(this.user);
  }
}
export interface User {
  Pono: string;  
  Party_id: number,
  Amount: null,
  Tdate: Date,
}
