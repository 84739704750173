import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { PoGenComponent } from '../po-gen.component';
import { MatDialog } from '@angular/material';
import { PdfListComponent } from '../pdf-list/pdf-list.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PoPaymentsComponent } from '../po-payments/po-payments.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'
import { AdminService } from 'src/app/Views/admin/admin.service';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css']
})
export class PoListComponent implements OnInit {

  loading: boolean = false;
  PoList: any = [];
  isClicked = false;
  DataForm: FormGroup;

  constructor(private PurchaseService: PurchaseService,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private dialog: MatDialog,
              private NotificationService: NotificationService,
              private fb: FormBuilder,
              private adminservice: AdminService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.GetDatas();
    this.DataForm = this.CreateFormGroup();
  }
  // AddItems() {
  //   (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());    
  // }
  AddPayment(data: any) {
    console.log(data);
    this.PoList.map(d => {
      d.isClicked = false;
    });
    data.isClicked = true;
    if (data.isClicked === true) {

      let amt: number = 0;
      let index: number = 0;
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      formarray.value.map(d => {
        let formgroup = (formarray.at(index) as FormGroup);
        amt = formgroup.get("Amount").value;
        index++;
      });

      console.log(index);
      if (amt > 0 || index <= 0) {
        (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
      }
    }
  }
  DeleteRow(i: number) {
    // if(this.ReceivedData.id > 1)
    //   {        
    //     console.log(this.ReceivedData.id);
    //     let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    //     let formgroup = (formarray.at(i) as FormGroup);
    //     formgroup.get('Noted').patchValue('D');              
    //   }
    //   else{
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
    // }  
  }
  GetDatas() {
    this.loading = true;
    this.PurchaseService.GetPOList()
      .subscribe((data: any) => {
        this.loading = false;
        data.map(d => {
          d.isClicked = false;
        });
        this.PoList = data;
        console.log(this.PoList);
        // this.PoList.map(p => {
        //   p.Podt = p.Podt.ToString("dd/MM/yyyy");
        // });        
      });
  }
  DownloadPDF(data: any) {
    let pono = data.Pono;
    let Party_name = data.Party_name;
    let Country = data.Country_nm;
    let headers = this.hardfunc.PostHeaders();

    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/CreatePoPDF?Pk_id=" + data.Pk_id + "&Revno=" + data.Revno
    this._http.post(url, null, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        const data = window.URL.createObjectURL(data1);

        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        var CurrDate = new Date();

        link.download = "PO" + pono + "-" + Party_name + "-" + Country + ".pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
  }
  UpdPayment(Party_id: number, Pono: number) {
    let index: number = 0;
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    formarray.value.map(d => {
      let formgroup = (formarray.at(index) as FormGroup);
      formgroup.get("Party_id").patchValue(Party_id);
      formgroup.get("Pono").patchValue(Pono);
      index++;
    });    
    this.PurchaseService.SaveImpPay(formarray.value)
      .subscribe((data: any) => {
        console.log(data);
        this.PoList.map(m => {
          if(m.Pono === Pono){
            console.log(m);
            moment(m.Tdate).format("DD/MM/yyyy");
            m.isClicked = false;
          }
        });
        this.NotificationService.success("Data Saved Successfully... ");
      });
  }
  DeletePay(Pk_id: number){
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		.afterClosed().subscribe(res =>{
		  if(res){
			this.loading = true;
			const dialogRef = this.PurchaseService.DelImpPay(Pk_id)
			.subscribe(
			  (data:any)=>{
				this.loading = false;
				this.NotificationService.warn("Data deleted successfully");	
        this.GetDatas();
			  },
			  err => {
				this.loading = false;
				let errstr =  this.hardfunc.getError(err);
				console.log(err);
				this.NotificationService.warn(errstr);
			  }
			)
			
			// this.notificationService('Deleted Successfully');
		  }
		});
  }
  PayDialog() {
    const dialogRef = this.dialog.open(PoPaymentsComponent, {
      width: '1000px',
      // data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.PoList.filter = filterValue.trim().toLowerCase();
  }
  PDFListDialog(data: any) {
    const dialogRef = this.dialog.open(PdfListComponent, {
      width: '200px',
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  DeletePo(Pk_id: number) {
    //, Revno: number
    // this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
    // .afterClosed().subscribe(res =>{
    //   if(res){
    this.loading = true;
    const dialogRef = this.PurchaseService.DeletePO(Pk_id)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.NotificationService.warn("Data deleted successfully");
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
        }
      )

    // this.notificationService('Deleted Successfully');
    //   }
    // });
  }
  POView(data: any) {
    console.log(data);

  }
  PO_Edit(data: any, Status: string) {
    const dialogRef = this.dialog.open(PoGenComponent, {
      // width: '1300px',
      height: '700px',
      data: { id: data.Pk_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  CreateFormGroup() {
    return this.fb.group({
      // Product_id: new FormControl(),
      // Product_nm: new FormControl(''),
      Pono: new FormControl(),
      SubDatas: this.fb.array([])
    })
  }
  CreateSubData(): FormGroup {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Tdate: new FormControl(new Date()),
      Pono: new FormControl(),
      Amount: new FormControl(0),
      Party_id: new FormControl(),
    });
  }
}
