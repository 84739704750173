import { Component, OnInit, Optional } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { PendordAttachComponent } from 'src/app/Views/sales/pendord/pendord-attach/pendord-attach.component';
import { MatDialog } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PendordService } from 'src/app/Views/sales/pendord/pendord.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { of } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.css']
})
export class RepositoryComponent implements OnInit {

  loading: boolean = false;
  PoList: any = [];
  PartyList: any = [];
  repmodel: any = {};
  filteredOptions: any = [];
  Party_name: string;
  DataForm: FormGroup;
  ProdListofParty: any = [];
  Ponolist: any = [];
  ImpoAtt: any = [];
  file: File | null = null;;
  datalist: any = [];
  Subdatalist: any = [];
  initialValues: any[];
  OtherDocu: any = [];

  constructor(private PurchaseService: PurchaseService,
    private dialog: MatDialog,
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private fb: FormBuilder,
    private POService: PurchaseService,
    private NotificationService: NotificationService,
    private CommonService: CommonService,
    private dmsservice: DmsService,
    private adminservice: AdminService
  ) { }

  ngOnInit() {
    this.GetDatas();
    this.GetPartyList();
    this.GetImpAtt();
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;
    this.DataForm.get("Party_nm").valueChanges.subscribe(response => {
      this._filter(response);
    });
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubDatas());
  }
  DeleteRow(i: number) {
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
  }
  SaveDocu() {
    if (!this.file) {
      alert('No file selected.');
      return;
    }    
    if (this.DataForm.get("Ordno").value === "") {
      this.NotificationService.warn("Please select Po number... ");
      return;
    }
    var formData = new FormData();
    formData.append("Files", this.file);
    formData.append("Data", JSON.stringify(this.DataForm.value));

    this.dmsservice.SaveImpoDocu(formData)
      .subscribe(
        data => {
          this.GetData();
          this.loading = false;
          this.NotificationService.success(' Data Saved Successfully... ');         
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  GetData() {
    this.loading = true;
    this.datalist = [];
    this.OtherDocu = [];
    this.dmsservice.GetDocu(this.hardfunc.Firmx, this.DataForm.get("Ordno").value, 1, "IP", 1163)
      .subscribe(
        (data1: any) => {          
          data1.map(m => {
            m.isClicked = false;
          });          
          this.datalist = data1;
          this.datalist.map(w => {            
            if(w.Docu_type === 887){
              this.OtherDocu.push(w);
            }
          });                    
          
          const formarr = new FormArray([]);         
          // if(data1){      
              
          // if(data1.length === 0){
          //   console.log(data1);
          //   (this.DataForm.controls['SubDatas'] as FormArray).removeAt(0);
          //   (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubDatas());
          // }              

          this.OtherDocu.map(m => {
            if (m.Docu_type === 887) {
              formarr.push(
                this.fb.group({                  
                  Docu_id: m.Docu_id,                 
                  Vtype: "IP", //this is available Vtype
                  Yrx: this.hardfunc.Yrx,
                  Firmx: this.hardfunc.Firmx,
                  Filename: m.Filename,
                  Ordno: m.Ordno,
                  Permission: m.Permission,
                  Oloc: 1,
                  Docu_type: m.Docu_type,
                  file: new FormControl(''),
                  Docu_name: m.Docu_name,
                  FileType: m.FileType,
                  Remarks: m.Remarks
                })
              );
              this.DataForm.setControl("SubDatas", formarr);
            }
          });          
        // }
          this.LoadImages();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      )
  }  
  LoadImages() {
    this.datalist.map(m => {
      if (!m.ExtenalUrl && m.Filename != null) {
        if (m.Filename.indexOf('.pdf') > 0 || m.Filename.indexOf('.PDF') > 0) {
          m.FileType = "PDF";
        }
        else if (m.Filename.toLowerCase().indexOf('.jpg') > 0 || m.Filename.toLowerCase().indexOf('.jpeg') > 0) {
          m.FileType = "IMG";
          this.LoadImagebyId(m)
        }
        else {
          m.FileType = "OTH";
        }

      }
    }
    )
  }
  LoadImagebyId(objFile: any) {
    this.loading = true;
    this.dmsservice.LoadImagebyId(objFile.Docu_id)
      .subscribe(
        (data1: any) => {
          objFile.ImageStr = 'data:image/jpeg;base64,' + data1;
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn("Error in image loading " + errstr);
        }
      )
  }
  NewForm(){
    let index = 0;
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);            
      formarray.value.map(d => {        
        let formgroup = (formarray.at(index) as FormGroup);                  
        (this.DataForm.controls['SubDatas'] as FormArray).removeAt(index); 
      });            
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubDatas());
  }
  DeleteAtt(Docu_id: number,i) {
    if(Docu_id > 0){
      this.loading = true;
      this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
        .afterClosed().subscribe(res => {
          this.loading = false;
          if (res) {
            const dialogRef = this.dmsservice.Delete_docu(Docu_id)
              .subscribe(
                (data: any) => {
                  this.loading = false;
                  this.NotificationService.warn("Data deleted succefully");                  
                  (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
                  if(i === 0){                    
                    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubDatas());
                  }
                  this.GetData();
                },
                err => {
                  this.loading = false;
                  let errstr = this.hardfunc.getError(err);
                  console.log(err);
                  this.NotificationService.warn(errstr);
                }
              )
          }
        });
    }
    else{
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
    }

  }
  GetDatas() {
    this.loading = true;
    this.PurchaseService.GetPOList()
      .subscribe((data: any) => {
        this.loading = false;
        this.PoList = data;
      }, err => {
        console.log(err);
      });
  }
  GetPartyList() {
    this.loading = true;
    this.PartyList = [];
    let data: any = {};
    data.Firmx = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatas";
    this._http.post(url, null, this.repmodel)
      .subscribe((data1: any) => {
        this.loading = false;
        this.PartyList = data1;
        this.PartyList = this.PartyList.map((x) => {
          return {
            nm: x.Party_name.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Party_id,
          }
        });
        this._filter("");
      }, err => {
        console.log(err);
      });
  }
  GetFile(event) {
    console.log(event);
    this.file = event.target.files[0];
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    let formgroup = (formarray.at(0) as FormGroup);
    formgroup.get('Ordno').patchValue(null);
  }
  AddGetFile(event) {
    this.file = event.target.files[0];    
    this.DataForm.get("Docu_type").patchValue(null);
  }
  GetOrdno(i: number) {
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    let formgroup = (formarray.at(i) as FormGroup);
    formgroup.get('Ordno').patchValue(this.DataForm.get("Ordno").value);
  }
  GetAttach(data: any) {
    console.log(data.Subcode_id);
    this.DataForm.get("Docu_type").patchValue(data.Subcode_id);
  }

  // 6. api/Common/CodemastsAPI/GetCodeListbyCodenm --API For --Order Status
  GetImpAtt() {
    this.loading = true;
    this.CommonService.CodeList('impoAtt')
      .subscribe(
        (data: any) => {
          this.ImpoAtt = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  GetPartyDetails() {
    this.Ponolist = [];  
    this.DataForm.get('Ordno').patchValue(null);
    this.PartyList.map(d => {
      if (d.nm === this.DataForm.get("Party_nm").value) {
        this.POService.GetDataParty(d.id)
          .subscribe(
            (data: any) => {
              this.POService.PartyPoList(data.Party_id)
                .subscribe((data: any) => {
                  this.Ponolist = data;
                });
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          );
      }
    });
  }

  // Po_attach(data: any) {
  //   console.log(data);
  //   const dialogRef = this.dialog.open(PendordAttachComponent, {
  //     width: '925px',
  //     height: '600px',
  //     data: { Ordno: data.Pono, Oloc: 1, id: data.Pk_id, Vtype: "IP", Wmodule_id: 1163 }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });
  // }
  ResetData() {
    // console.log(this.file.nativeElement.files);
    // this.myInputVariable.nativeElement.value = "";
    // console.log(this.myInputVariable.nativeElement.files);    
  }
  CreateFormGroup() {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Party_nm: new FormControl(''),
      Vtype: new FormControl("IP"), //this is available Vtype
      Yrx: new FormControl(''),
      Firmx: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(),
      Oloc: new FormControl(1),
      Notes_type: new FormControl(),
      Docu_type: new FormControl(''),
      Remarks: new FormControl(''),
      file: new FormControl(''),
      Docu_name: new FormControl(''),
      ExtenalUrl: new FormControl(''),
      SubDatas: this.fb.array([this.CreateSubDatas()]),
    });
  }
  CreateSubDatas() {
    return this.fb.group({
      Docu_id: new FormControl(-1),
      Pk_id: new FormControl(-1),
      Party_nm: new FormControl(''),
      Vtype: new FormControl("IP"), //this is available Vtype
      Yrx: new FormControl(''),
      Firmx: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(),
      Oloc: new FormControl(1),
      Notes_type: new FormControl(),
      Docu_type: new FormControl(887),
      Remarks: new FormControl(''),
      file: new FormControl(''),
      Docu_name: new FormControl(''),
      Filename: new FormControl(''),
      ExtenalUrl: new FormControl(''),
    });
  }
  private _filter(value: string) {
    if (!value) {
      this.filteredOptions = this.PartyList;
      return;
    }
    if (value.length <= 0) {
      this.filteredOptions = this.PartyList;
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyList.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
}
