import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { IndentRegComponent } from '../../purchase/indent-reg/indent-reg.component';

@Component({
  selector: 'app-prod-select',
  templateUrl: './prod-select.component.html',
  styleUrls: ['./prod-select.component.css']
})
export class ProdSelectComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  repmodel: any = {};
  HelpList: any = [];
  filteredOptions: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  PrdGroupList: any = [];
  GrpList: any = [];
  TempData: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  msgFromChild1 = [];
  
    constructor(private fb: FormBuilder,
                private hardfunc: HardFuncService,
                private _http: HttpClient,
                @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
                private _cservice: CommonService,
                private NotificationService: NotificationService,
                private _snackBar: MatSnackBar,
                private manufacturingService: ManufacturingService,
                private dialogRef: MatDialogRef<IndentRegComponent>,) { }
  
    ngOnInit() {
      // this.DataForm = this.HelpFormGroup();
      // this.loading = true;
      // this.GetProductById();
      this.PrdGrpList();
    }
    // msgToParent(Product_id: number, Product_nm: string) {
    //   this.msgFromChild1.push(Product_id);
    //   this.msgFromChild1.push(Product_nm);
    //   this.msgFromChild1.push(this.ReceivedData)
    // }
    msgToParent() {
      this.ReceivedData = this.TempData;
      console.log(this.ReceivedData);
      this.dialogRef.close(this.TempData);
    }
    SaveHelp() {
      
    }
    DisplayCol() {	
      let isSm = window.matchMedia(`(max-width: 960px)`).matches;
      this.displayedColumns = ['Product_id','Short_nm'];
    }
    // GetProductById() {
    //   this.HelpList = [];
    //   let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductList";
    //   this._http.post(url, this.repmodel)
    //     .subscribe((data1: any) => {
    //       data1.map(d => {
    //         d.Chk = false;
    //       });
    //       this.HelpList = data1;
    //       console.log(this.HelpList);
    //       // this.dataSource = new MatTableDataSource(<any>this.HelpList);
    //       // this.dataSource.paginator = this.paginator;
    //       this.DisplayCol();
    //     }, err => {
    //       console.log(err);
    //     });
    // }
    PrdGrpList(){
      this.loading = true;
      this._cservice.PrdGroupList() // 2. api/PrdGroupsAPI/GetPrdGroup -- API For --Get Product Group
      .subscribe((data: any) => {
        data.map(d => {
          d.Chk = false;
        });
        this.PrdGroupList = data;
        console.log(data);
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
    }
    ItemList(){      
      this.HelpList = [];
      this.loading = true;
      this.manufacturingService.ProductItems(this.repmodel.Grp_id)
        .subscribe((data: any) => {
          console.log(data);
          this.HelpList = data;
          // this.dataSource = new MatTableDataSource(<any>this.GrpList);
          // this.dataSource.paginator = this.paginator;
          this.loading = false;
          
        },err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        });
    }
    selectItems(data: any) {
      // if(data.Chk === true){
      this.TempData.push(data);
      // }    
    }
    
    // HelpFormGroup() {
    //   return this.fb.group({
    //     Product_id: new FormControl(),
    //     Product_nm: new FormControl(''),
    //   });
    // }
    applyFilter(event: Event) {
      console.log(event);
      const filterValue = (event.target as HTMLInputElement).value;
      this.HelpList.filter = filterValue.trim().toLowerCase();
    }
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 2000,
      });
    }

}
