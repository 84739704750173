import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SalesService } from '../../sales/sales.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Component({
  selector: 'app-rc-party-help',
  templateUrl: './rc-party-help.component.html',
  styleUrls: ['./rc-party-help.component.css']
})
export class RcPartyHelpComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  msgFromChild1 = [];
  isClicked = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private SalesService: SalesService,
    private NotificationService: NotificationService,
    private hardfunc: HardFuncService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {    
    this.GetData();
  }
  GetData() {
    this.loading = true
    this.SalesService.GetRcList()
      .subscribe((data: any) => {        
        data.map(d => {
          d.SubDatas.map(m => {
            m.Hsn_code = m.Hsn_code || 0;
            m.GSTPer = m.GSTPer || 0;
            m.isClicked = false;
          });
        });
        this.datalist = data;        
        this.loading = false;
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        });

  }

  HsnUpdate(Grp_id: number, Product_id: number, Hsn_code: string) {
    this.loading = true;
    console.log(Grp_id);
    console.log(Product_id);
    console.log(Hsn_code);

    this.SalesService.HsnUpdate(Grp_id, Product_id, Hsn_code)
      .subscribe((data: any) => {
        this.datalist.map(d => {
          d.MaxClicked = false;
          d.isClicked = false;
          d.CatClicked = false;
          this.GetData();
        });
        this.loading = false;
        this.NotificationService.success("Data Saved Sucessfully...");
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }

  GstperUpdate(Grp_id: number, Product_id: number, GSTPer: number) {
    this.loading = true;
    console.log(Grp_id);
    console.log(Product_id);
    console.log(GSTPer);
    this.SalesService.GstperUpdate(Grp_id, Product_id, GSTPer)
      .subscribe((data: any) => {
        this.datalist.map(d => {
          d.MaxClicked = false;
          d.isClicked = false;
          d.CatClicked = false;
        });
        this.loading = false;
        this.NotificationService.success("Data Saved Sucessfully...");
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }

  msgToParent(data: any) {
    this.msgFromChild1.push(data);
    this.msgFromChild1.push(this.ReceivedData)
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}