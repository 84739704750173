import { Component, OnInit } from '@angular/core';
import { SalesService } from '../sales.service';
import { CrEntryComponent } from '../cr-entry/cr-entry.component';
import { MatDialog } from '@angular/material';
import { PendordAttachComponent } from '../pendord/pendord-attach/pendord-attach.component';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CrMailComponent } from '../cr/cr-mail/cr-mail.component';

@Component({
  selector: 'app-cr-list',
  templateUrl: './cr-list.component.html',
  styleUrls: ['./cr-list.component.css']
})
export class CrListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  PartyMail: any = [];
  r1: any = {};
  party_name: string;
  
  constructor(private SalesService: SalesService,
              private dialog: MatDialog,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,) { }

  ngOnInit() {
    this.loading = false;
    this.GetDatas();
  }
  GetDatas(){    
    this.loading = true;
    this.SalesService.GetCrData()
      .subscribe((data: any) => {             
        this.datalist = data;
        console.log(this.datalist);
        // console.log(this.datalist);
        this.loading = false;        
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });    
  }
  CrEmail(data: any){
    console.log(data);    
    this.SalesService.GetCrDatabyId(data.Conrw_id)
      .subscribe((data1: any) => {    
        console.log(data1);
        data.QuoteConts = data1.QuoteConts;        
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    console.log(data);
    const dialogRef = this.dialog.open(CrMailComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { data: data, Atts: data, Type: 'Cr' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });                         
  });
    //<!-----=========================== Actual Mail Part ===========================----->    
  }
  EditCr(Conrw_id: number,Status: string){    
    const dialogRef = this.dialog.open(CrEntryComponent, {
      width: '1600px',
      height: '800px',
      data: { id: Conrw_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {            
      console.log('The dialog was closed ');            
    });
  }
  ReviseCr(Conrw_id: number,Status: string){
    const dialogRef = this.dialog.open(CrEntryComponent, {
      width: '1600px',
      height: '800px',
      data: { id: Conrw_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');            
    });
  }
  CrAttachment(Qot_no: number, Conrw_id: number){    
    const dialogRef = this.dialog.open(PendordAttachComponent, {
      width: '925px',
      height: '600px',
      data: { Ordno: Qot_no, Oloc: 1, id: Conrw_id, Vtype: 'CR', Wmodule_id: 1144 }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datalist.filter = filterValue.trim().toLowerCase();
  }
}
