import { Component, OnInit } from '@angular/core';
import { SalesService } from '../../sales.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Console } from 'console';
import { RcMastComponent } from '../rc-mast/rc-mast.component';
import { MatDialog } from '@angular/material';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PendordAttachComponent } from '../../pendord/pendord-attach/pendord-attach.component';

@Component({
  selector: 'app-old-arc',
  templateUrl: './old-arc.component.html',
  styleUrls: ['./old-arc.component.css']
})
export class OldArcComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  Party_name: string;
  ItemSearch: string;
  
  constructor(private SalesService: SalesService,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              private dialog: MatDialog,
              private AdminService: AdminService,
              private _http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
    this.GetData();
  }
  GetData() {
    this.loading = true
    this.SalesService.GetRcList()
      .subscribe((data: any) => {
        this.datalist = data;        
        this.loading = false;
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        });
  }
  
  EditRc(Rc_id: number,Status: string) {
    const dialogRef = this.dialog.open(RcMastComponent, {
      width: '1400px',
      height: '648px',
      data: { id: Rc_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ReviseRc(Rc_id: number,Status: string) {
    const dialogRef = this.dialog.open(RcMastComponent, {
      width: '1400px',
      height: '648px',
      data: { id: Rc_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  RcAttachment(Rc_id: number){    
    const dialogRef = this.dialog.open(PendordAttachComponent, {
      width: '925px',
      height: '600px',
      data: { Ordno: Rc_id, Oloc: 1, Vtype: 'RC', Wmodule_id: 1144 }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  DeleteRc(Rc_id: number) {
    this.AdminService.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.loading = true;
          const dialogRef = this.AdminService.DeleteRc(Rc_id)
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.NotificationService.warn("Data deleted successfully");
                // this.GetData();
              },
              err => {
                this.loading = false;
                let errstr = this.hardfunc.getError(err);
                console.log(err);
                this.NotificationService.warn(errstr);
              }
            )

          // this.notificationService('Deleted Successfully');
        }
      });
  }

}
