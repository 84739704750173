import { Component, OnInit, Optional, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { EmailserviceService } from 'src/app/Views/shared/email/emailservice.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { EnqService } from '../../Enq/Enq.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { SalesService } from '../../sales.service';
import { QuoteListComponent } from '../../quote-crud/quote-list/quote-list.component';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/Services/Common/common.service';
import { C, COMMA, ENTER, X } from '@angular/cdk/keycodes';
import { TempmastComponent } from 'src/app/Views/shared/tempmast/tempmast.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import * as moment from 'moment'

@Component({
  selector: 'app-cr-mail',
  templateUrl: './cr-mail.component.html',
  styleUrls: ['./cr-mail.component.css']
})
export class CrMailComponent implements OnInit {

  loading: boolean = false;
    DataForm: FormGroup;
    public editor = ClassicEditor;
    SelectedFile: File = null;
    Msgdata1: any = [];
    On: any = [];
    SenderEmail: any = [];
    Attach: any = [];
    Atts: any = [];
    NewAtts: any = [];
    file: any = [];
    To: any = [];
    r1: any = {};
    MsgFile: boolean = false;
    filename: any = [];
    datalist: any = [];
    SrNumber: any;
    receive: any = [];
    formAtt: any = {};
    quoteAtt: any = {};
    quoteCont: any = [];
    email: any = [];
    Users: string[] = [];
    CC_Users: string[] = [];
    Bcc_Users: string[] = [];
    filteredUsers: any = [];
    filtered_cc: any = [];
    filtered_bcc: any = [];
    UserData: any = [];
    EmailData: any = [];
    Emailtbl: any = [];
    Emailcc: any = [];
    EmailBcc: any = [];
    visible = true;
    removable = true;
    addOnBlur = true;
    cc_visible = true;
    cc_remove = true;
    cc_addOnBlur = true;
    initialValues: any[];
    MailBody: any = [];
    repmodel: any = {};
    tdata: any = [];
    itemDetail: any = {};
    Signature: any = {};
    Emaildata: any = {};
    MsgData: any = [];
    Enqdata: any = [];
    PartyDocu: any = [];
    SubDatas: any = [];
    Username: string = '';
    ShortName: any = [];
    EmailList: any = [];
    
    separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('UserInput', { static: false }) UserInput: ElementRef<HTMLInputElement>;
    @ViewChild('UserInputcc', { static: false }) UserInputcc: ElementRef<HTMLInputElement>;
    @ViewChild('UserInputBcc', { static: false }) UserInputBcc: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('auto1', { static: false }) matAutocomplete1: MatAutocomplete;
    @ViewChild('auto2', { static: false }) matAutocomplete2: MatAutocomplete;
  
    //url: string = this.Hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";
    constructor(public fb: FormBuilder,
      public EmailService: EmailserviceService,
      public NotificationService: NotificationService,
      private Hardfunc: HardFuncService,
      private _http: HttpClient,
      @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
      @Optional() public dialogRef: MatDialogRef<QuoteListComponent>,
      private Enqservice: EnqService,
      private salesService: SalesService,
      private snackBar: MatSnackBar,
      private commonservice: CommonService,
      private dialog: MatDialog,
      private adminservice: AdminService) { }
  
    ngOnInit() {
      this.loading = true;  
      
      this.receive = this.ReceivedData.Atts;
      if(this.ReceivedData.Type === "Cr"){
        console.log(this.receive);
      }    
      this.Username = this.Hardfunc.UserName;
      this.DataForm = this.createFormGroup();
      
      this.GetEmailList();
      this.initialValues = this.DataForm.value;    
      this.GetEmail();
      
      this.GetQuoteBody();
      //Email To --Set Value    
      if (this.ReceivedData.Type === 'Quote' || this.ReceivedData.Type === 'Cr') {
  
        this.quoteCont = this.ReceivedData.data.QuoteConts;
        console.log(this.quoteCont);
        this.email = "";
        this.quoteCont.map(q => {
          console.log(q);
          this.email = q.Email;
          if(this.email){
            this.Users.push(this.email);
          }        
        });
              
        //Document Attachment
        this.formAtt = this.ReceivedData.Atts.Doculist || [];
        console.log(this.formAtt);
        // this.formAtt.map(m => {        
        //   console.log(m);
        //   if(m.ExtenalUrl === null){          
        //     this.Atts.push(m);
        //   }                
        // });
  
        //Quotation Attachment      
        this.quoteAtt = this.ReceivedData.Atts;
        console.log(this.quoteAtt);
        // this.quoteAtt.Doculist.map(m => {        
        //     if(m.ExtenalUrl){
        //       m.Vtype = "URL";
        //     }else{
        //       m.Vtype = "QQ";
        //       m.FileName = this.quoteAtt.QuotAtt    
        //     }
        //     console.log(m);
        //     this.Atts.push(m);
        // })
        console.log(this.quoteAtt);
        var qarr: any = {
          Vtype: "QQ",
          FileName: this.quoteAtt.QuotAtt || this.quoteAtt.CrAtt
        }
        this.Atts.push(qarr);
      }
      if(this.ReceivedData.Type === 'Cr'){            
        this.Users.push('it@newage-india.in');  //orders@newage-india.in
  
        this.salesService.GetHandlebyId(this.ReceivedData.data.Fld_id)
            .subscribe((data: any) => {            
              this.Users.push(data.Emails.trim());
            });
        this.salesService.GetHandlebyId(this.ReceivedData.data.Dir_id)
        .subscribe((data: any) => {        
          this.CC_Users.push(data.Emails.trim());
        });                   
      }
      if(this.ReceivedData.Type === 'Enq'){
        this.Users.push(this.receive.Email_id);
        this.DataForm.get('Body').patchValue(this.receive.Body +"<br><br>"+ this.receive.Emailsign);
      }
  
      this.filteredUsers = this.DataForm.get('UserCtrl').valueChanges.pipe(
        startWith(null),
        map((User: string | null) => User ? this._filter(User) : this.EmailList.slice()));
      this.filtered_cc = this.DataForm.get('UserCtrl2').valueChanges.pipe(
        startWith(null),
        map((User: string | null) => User ? this.CC_filter(User) : this.EmailList.slice()));
      this.filtered_bcc = this.DataForm.get('UserCtrl3').valueChanges.pipe(
        startWith(null),
        map((User: string | null) => User ? this.Bcc_filter(User) : this.EmailList.slice()));
    }
  
    SaveData() {
      console.log(this.ReceivedData.data1);
      this.loading = true;
       
      // Email To    
      let emailstr: string = "";
      this.Users.map((w: any) => {      
        emailstr += w + ";";
      });
      this.SaveEmailId();
      let emailccstr: string = "";
      this.CC_Users.map((w: any) => {
        emailccstr += w + ";";
      });
      let emailBccstr: string = "";
      this.Bcc_Users.map((w: any) => {
        emailBccstr += w + ";";
      });
      this.DataForm.controls["Email_to"].patchValue(emailstr);
      this.DataForm.controls["Email_cc"].patchValue(emailccstr);
      this.DataForm.controls["Email_bcc"].patchValue(emailBccstr);    
  
      //Sent mail Details
      let Body = this.DataForm.get("Body").value;
      const encodedString = encodeURIComponent(Body);
      const base64String = btoa(encodedString);
      
      let Emails = {
        Email_id: this.DataForm.get("Email_id").value,
        Email_to: this.DataForm.get("Email_to").value,
        Email_cc: this.DataForm.get("Email_cc").value,
        Email_bcc: this.DataForm.get("Email_bcc").value,
        Email_from: this.DataForm.get('Email_from').value,
        Subject: this.DataForm.get("Subject").value,
        Folder_nm: this.DataForm.get("Folder_nm").value,
        Body: base64String,
        Qot_no: this.ReceivedData.data.Qot_no,
        Attachment: this.Atts
      }
      var formData = new FormData();
      let Counter: number = 0;
  
      this.NewAtts.map(m => {      
        formData.append("Files" + Counter, m);            
        Counter++;
        var at1 = {
          "FileName": m.name,
          "FileType": m.type,
          "RowData": File,
          "Tag": 'N'
        }
        this.Atts.push(at1);
      });
  
      let PartyCount: number = 0;
      this.PartyDocu.map(m => {
        formData.append("Files" + PartyCount, m);
        PartyCount++;
        this.Atts.push(m);
      });    
          console.log(this.Atts);
      formData.append("Data", JSON.stringify(Emails));
  
      formData.append("AttData", JSON.stringify(this.Atts));
      formData.append("Party", JSON.stringify(this.PartyDocu));    
      console.log(formData);
      this.salesService.SaveQuotation(formData)
        .subscribe(
          data => {         
            this.loading = false;
            this.loading.valueOf()
            this.NotificationService.success(" Mail Sent Successfully... ");
            this.dialogRef.close();          
          },
          err => {
            console.log(err);
            this.loading = false;
            let errstr = this.Hardfunc.getError(err);
            this.NotificationService.warn(errstr);
          }
        )
    }
  
    // Email List
    GetEmailList() {
      this.loading = true;
      this.commonservice.CodeList('Email_List')
        .subscribe(
          (data: any) => {          
            this.EmailList = JSON.parse(data);
            this.loading = false;
          },
          err => {
            this.loading = false;
            let errstr = this.Hardfunc.getError(err);
            this.NotificationService.warn(errstr);
          }
        );
    }
  
    SaveEmailId(){    
      // Email To
      this.Users.map(w => {
        var data: any = {
          Codemast_id: 29,
          Subcode_id: -1,
          Codenm: "",
          Vals: w,
          Cd: "",
        }
        this.salesService.SaveEmailId(data)
          .subscribe((data: any) => {
            console.log(data);
          });
      });  
      
      // Email CC
      this.CC_Users.map(w => {
        var data: any = {
          Codemast_id: 29,
          Subcode_id: -1,
          Codenm: "",
          Vals: w,
          Cd: "",
        }
        this.salesService.SaveEmailId(data)
          .subscribe((data: any) => {
            console.log(data);
          });
      });    
    }
    add(event: MatChipInputEvent): void {
      if (!this.matAutocomplete.isOpen) {
        const input = event.input;
        const value = event.value;
  
        if ((value || '').trim()) {
          this.Users.push(value.trim());
        }
  
        if (input) {
          input.value = '';
        }
  
        this.DataForm.get('UserCtrl').setValue(null);
      }
    }
    addEmailCC(event: MatChipInputEvent): void {
      if (!this.matAutocomplete1.isOpen) {
        const input = event.input;
        const value = event.value;
  
        if ((value || '').trim()) {
          this.CC_Users.push(value.trim());
        }
  
        if (input) {
          input.value = '';
        }
        this.DataForm.get('UserCtrl2').setValue(null);
      }
    }
    addEmailBcc(event: MatChipInputEvent): void {
      if (!this.matAutocomplete2.isOpen) {
        const input = event.input;
        const value = event.value;
  
        if ((value || '').trim()) {
          this.Bcc_Users.push(value.trim());
        }
  
        if (input) {
          input.value = '';
        }
        this.DataForm.get('UserCtrl3').setValue(null);
      }
    }
  
    remove(User: string): void {
      const index = this.Users.indexOf(User);
  
      if (index >= 0) {
        this.Users.splice(index, 1);
      }
    }
    removeCC(User: string): void {
      const index = this.CC_Users.indexOf(User);
  
      if (index >= 0) {
        this.CC_Users.splice(index, 1);
      }
    }
    removeBcc(User: string): void {
      const index = this.Bcc_Users.indexOf(User);
  
      if (index >= 0) {
        this.Bcc_Users.splice(index, 1);
      }
    }
  
    selected(event: MatAutocompleteSelectedEvent): void {
      this.Users.push(event.option.viewValue);
      this.UserInput.nativeElement.value = '';
      this.DataForm.get('UserCtrl').setValue(null);
    }
    selectedCC(event: MatAutocompleteSelectedEvent): void {
      this.CC_Users.push(event.option.viewValue);
      this.UserInputcc.nativeElement.value = '';
      this.DataForm.get('UserCtrl2').setValue(null);
    }
    selectedBcc(event: MatAutocompleteSelectedEvent): void {
      this.Bcc_Users.push(event.option.viewValue);
      this.UserInputBcc.nativeElement.value = '';
      this.DataForm.get('UserCtrl3').setValue(null);
    }
  
    GetEmail() {
      this.loading = true;
      this.commonservice.getAllUsers()
        .subscribe(
          (data: any) => {
  
            this.Emailcc = data;
            this.Emailcc = this.Emailcc.map((x) => {
              return {
                nm: x.Email,
                id: x.Id
              }
            });
            this.EmailBcc = data;
            this.EmailBcc = this.EmailBcc.map((x) => {
              return {
                nm: x.Email,
                id: x.Id
              }
            });
            this.EmailData = data;
            this.EmailData = this.EmailData.map((x) => {
              return {
                nm: x.Email,
                id: x.Id
              }
            });
            this.loading = false;
          },
          err => {
            let errstr = this.Hardfunc.getError(err);
            this.NotificationService.warn(errstr);
            this.loading = false;
          });
    }
  
    ResetData() {
      this.dialogRef.close();
    }
  
    //Email Body
    GetQuoteBody() {
      this.Enqdata = this.ReceivedData.data.EnqMail;    
        var Docu_type: number = 0;
        this.Enqdata.map(e => {        
          Docu_type = e.Docu_type;
          this.r1.Filename = e.Filename;
        });
        let fn: string = this.r1.Filename || "";
        this.r1.Output = "JSN2";
        // let DocuType = "DMS" + Docu_type;
        this.r1.DocuType = "DMS" + Docu_type;         
        
        this.salesService.EnqPartyMail(this.r1)
          .subscribe((data1: any) => {            
            console.log(data1);
            if(data1.SenderName){            
              console.log(data1.SenderName)
              this.ShortName = data1.SenderName.split(" ");          
              var str1 = this.ShortName[0].substring(0,1);
              var str2 = this.ShortName[1].substring(0,1);          
              var SenderName = str1 + str2;  
            }
  
            data1.Atts.map(d => {                  
                d.Vtype = "PA";
                this.PartyDocu.push(d);            
            });          
            
            this.DataForm.get("Subject").patchValue(data1.Subject +" "+ str1+str2+this.ReceivedData.data.Qot_no);  
            var todaydt = new Date();
                      
            var QtnRef = " "+str1+str2 + this.ReceivedData.data.Qot_no + "-" + "R"+this.ReceivedData.data.Qot_ver; 
            
            //Get Party Body part 
            let d = this.ReceivedData.data;                  
            
            if(this.ReceivedData.Type === 'Quote'){
              this.salesService.quoteEmail(d.Quote_id,SenderName)
              .subscribe((data: string) => {        
                console.log(data);
                this.DataForm.get("Body").patchValue(data + "<br>" + data1.Body); //
              });                       
            }          
            if(this.ReceivedData.Type === 'Cr'){
              this.DataForm.get("Body").patchValue("Dear Bharatbhai," + "<br>" + "Please find enclosed CR,PO.." + "<br>" + data1.Body); //
            }            
            });
    }
    
    // Email Body
    TempmastDialog(Vtype: string, Fldname: string) {
      let isSm = window.matchMedia(`(max-width: 960px)`).matches;
      let w = '90%';
      let h = '80%';
      if (isSm) {
        w = '100%';
        h = '100%';
      }
      const dialogRef = this.dialog.open(TempmastComponent, {
        width: w,
        maxWidth: w,
        height: h,
        data: { Vtype: Vtype, Fldname: Fldname }
      });
      dialogRef.afterClosed().subscribe(result => {      
        let str = result.Tempstr;
        // str = str.replace("{{Party_name}}", this.ReceivedData.data.Party_name);
        // str = str.replace("{{Address}}", this.ReceivedData.data.Party_add);
  
        // //Payment Terms
        // str = str.replace("{{Terms}}",
        //   "<table style='width: 80%;'>" +
        //   "<tr>" +
        //   "<td style='width: 20%;'> <b>a) Price Basic :</b> </td>" +
        //   "<td style='width: 80%;'>" + this.ReceivedData.data.T_price + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "<tr>" +
        //   "<td><b>b) Payment Collection : </b></td>" +
        //   "<td>" + this.ReceivedData.data.T_validity + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "<tr>" +
        //   "<td><b>c) Payment Collection : </b></td>" +
        //   "<td>" + this.ReceivedData.data.T_paym + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "<tr>" +
        //   "<td><b>d) Delievery Period : </b></td>" +
        //   "<td>" + this.ReceivedData.data.T_deli + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "<tr>" +
        //   "<td><b>e) Gst Terms : </b></td>" +
        //   "<td>" + this.ReceivedData.data.T_gst + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "<tr>" +
        //   "<td><b>f) Security Depo. : </b></td>" +
        //   "<td>" + this.ReceivedData.data.T_guarantee + "<br><br>" + "</td>" +
        //   "</tr>" +
        //   "</table>"
        // );
  
        // //Item Details
        // let d = this.ReceivedData.data;
        // this.salesService.quoteEmail(d.Quote_id)
        //   .subscribe((data1: string) => {
        //     console.log(data1);
        //     str = str.replace("{{Item_detail}}", data1);
        //     this.DataForm.get("Body").patchValue(str);
        //   });
  
        // //Party mail from enquiry
        // this.Enqdata = this.ReceivedData.data.EnqMail;
        // var Docu_type: number = 0;
        // this.Enqdata.map(e => {        
        //   Docu_type = e.Docu_type;
        //   this.r1.Filename = e.Filename;
        // });
        // // let fn: string = this.r1.Filename || "";
        // // this.r1.Output = "JSN2";
        // // let DocuType = "DMS" + Docu_type;
        // // this.r1.DocuType = DocuType;      
        // this.salesService.EnqPartyMail(this.r1)
        //   .subscribe((data1: any) => {          
        //     this.MsgData = data1;
        //     str = str.replace("{{Partymail}}", this.MsgData.Body);
        //     this.DataForm.get("Body").patchValue(str);
        //     this.DataForm.get("Subject").patchValue(this.MsgData.Subject);
        //   });
  
        // this.salesService.getUserbyUsername(this.Username)
        //   .subscribe(
        //     (data: any) => {
        //       this.Emaildata = data;
        //       str = str.replace("{{Signature}}", this.Emaildata.Emailsign);
        //       this.DataForm.get("Body").patchValue(str);
        //     });
  
      // Help mail ================================================================================
      
      this.Enqdata = this.ReceivedData.data.EnqMail;    
        var Docu_type: number = 0;
        this.Enqdata.map(e => {        
          Docu_type = e.Docu_type;
          this.r1.Filename = e.Filename;
        });
        let fn: string = this.r1.Filename || "";
        this.r1.Output = "JSN2";
        let DocuType = "DMS" + Docu_type;
        this.r1.DocuType = DocuType;   
  
        //Get Signature User EmailSign
        this.salesService.getUserbyUsername(this.Username)
        .subscribe(
          (data: any) => {              
            this.Emailtbl = data;              
          });
        
        // Variable Delivery Terms & Cond
        var delivery = "";
        if (this.ReceivedData.data.T_deli == "" || this.ReceivedData.data.T_deli == null)
        {
          delivery = "Delivery period shall be subject to availability of material in stock. It will be buyer’s responsibility to check the readiness / availability of the material before placing the Purchase Order / issuing dispatch clearance. We cannot be held liable for delays due to unforeseen circumstances beyond our control.";
        }
        else
        {
          delivery = "Delivery period shall be <b>"+this.ReceivedData.data.T_deli+"</b> from receipt of confirmed techno commercial Purchase Order."+
                                " <br> Delivery period shall be subject to availability of material in stock. It will be buyer’s responsibility to check the readiness / availability of the material before placing the Purchase Order / issuing dispatch clearance. We cannot be held liable for delays due to unforeseen circumstances beyond our control.";
        }
        
        this.salesService.EnqPartyMail(this.r1)
          .subscribe((data1: any) => {  
            
            this.ShortName = data1.SenderName.split(" ");
            var str1 = this.ShortName[0].substring(0,1);
            var str2 = this.ShortName[1].substring(0,1);
            
            data1.Atts.map(d => {                  
                d.Vtype = "PA";
                this.PartyDocu.push(d);            
            });          
            
            this.DataForm.get("Subject").patchValue(data1.Subject +" "+ str1+str2+this.ReceivedData.data.Qot_no);  
            var todaydt = new Date();
            
            // Get Quote Contact Details
            var ContName = "";
            var ContPhone = "";
            var ContEmail = "";
            var PDetail = "";
            var space = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
            this.quoteCont.map(x => {
              ContName = x.Cname;
              ContPhone = x.Phone;
              ContEmail = x.Email;
            });
            // Party Body
            
            var QtnRef = " "+str1+str2 + this.ReceivedData.data.Qot_no + "-" + "R"+this.ReceivedData.data.Qot_ver; 
            PDetail = str.replace("{{PartyDetail}}", "QNT REF : " + QtnRef + "  " + space + " Date : "+ moment(todaydt).format("DD/MM/yyyy") +" <br><br>" +                          
                            "To, <br>" +
                            "Mr. "+ContName+"<br>" +
                            "<b> "+this.ReceivedData.data.Party_name+" </b><br>" +
                            " "+this.ReceivedData.data.Party_add+" <br>" +
                            "Mo : "+ContPhone+" <br> "+
                            "E-mail : "+ContEmail+" <br><br>" +
                            "Ref. : "+this.ReceivedData.data.Party_ref+" <br><br>" +
                            "Dear Sir, <br>"+
                            "Reference to above, pls. find our most competitive Offer as under: ");
                            
            // Terms & Conditions
            var TermsCond = "<b>NOTE:</b><br>"+
                            "<b>1. W.e.f. 01.10.2020, as per the Finance Act, 2020 and the Section 206C(1H) of Income Tax Act, 1961, we shall charge TCS @"+
                            "0.1% on our sales consideration exceeding Rs. 50,00,000/- in current year.</b><br>"+
                            "<b>2. Please find below the Terms & Conditions associated with our above quote. By accepting the quote, the buyer agrees to"+
                            "comply with and be bound by these Terms & Conditions.</b>"+
                            "<h3><u>Terms & Conditions</u></h3>" +
                            "<table style='border: 1px solid;width: 80%;border-collapse: collapse;padding: 5px;'>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Scope</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " Our scope shall be limited to the supply of the products only." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Product & Services</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " We offer a range of fire fighting products as detailed in our quote. We reserve the right to modify or"+
                            "discontinue any product at any time." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Price Basis</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " All prices stated in the quote are inclusive of Packing & Forwarding charges and are on <b>"+this.ReceivedData.data.T_price+"</b>."+
                            "We reserve the right to change prices without prior notice." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> GST Charges</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + "<b>" + this.ReceivedData.data.T_gst+ "</b>" + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Transportation</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + "<b>" + this.ReceivedData.data.Transport+"</b>" + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Transit Insurance</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " Buyer will be responsible for Transit insurance and shall arrange at their own cost. In case of any"+
                            "damage or loss during transportation, the buyer will be responsible for filing a claim with the"+
                            "insurance company or bearing the loss themselves." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Payment Terms</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " Payment terms will be <b>"+this.ReceivedData.data.T_paym+"</b>. Late payments may be subject to"+
                            "interest charges @ 12% per annum." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Delivery Time</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " " + delivery+" " + 
                            "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Warranty</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " We warrant that the products will be free from defects in material and workmanship for a period of "+
                            "<b>"+this.ReceivedData.data.T_guarantee+"</b> from the date of invoice, under normal use and maintenance. This warranty, however,"+
                            "does not apply to products subject to normal wear, abrasion, snags, cutting, holes, tears, punctures,"+
                            "burning, melting, chemical attack or contamination, or any damage resulting from accident,"+
                            "mistreatment, misuse, abuse, improper installation, corrosion, exposure in extreme environment,"+
                            "unauthorized repair, alteration, un-maintained or neglect. Damage due to mildew or rot are also"+
                            "excluded. This warranty expressly excludes all incidental or consequential damages." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Validity of Offer</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " This quote shall remain valid for a period of <b>"+this.ReceivedData.data.T_validity+"</b> from the date of this quote. If the buyer"+
                            "requires more time to consider our offer, we will do our best to extend the offer upon their request." + 
                            "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Limitation of Liability</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " We shall not be liable for any indirect, incidental, special, or consequential damages arising out of or"+
                            "in connection with this quote, including but not limited to loss of profits, loss of business, or loss of"+
                            "goodwill. Under no circumstances shall our liability for any claim related to our products or services"+
                            "exceed the total amount paid by the buyer for the products supplied under this quote." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Indemnification</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " The buyer shall indemnify, defend, and hold harmless the Company and its affiliates, officers,"+
                            "directors, employees, agents, successors, and assignee from and against any and all claims, damages,"+
                            "liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or in connection"+
                            "with the buyer’s use or resale of the products supplied under this quote." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Dispute Resolution and Governing Law</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " Any dispute arising out of or in connection with this quote, including any question regarding its"+
                            "existence, validity, or termination, shall be referred to and finally resolved by arbitration under the"+
                            "rules of the Indian Arbitration and Conciliation Act, 1996. The parties shall jointly appoint a sole"+
                            "arbitrator within 30 days of the receipt of the notice of arbitration. If the parties fail to agree on the"+
                            "appointment of the arbitrator, the arbitrator shall be appointed by the Hon’ble District Court,"+
                            "Surendranagar. The arbitration shall be conducted in English and the seat of arbitration shall be in"+
                            "Surendranagar District, Gujarat, India. The award rendered by the arbitrator(s) shall be final and"+
                            "binding upon the parties. This quote shall be governed by and construed in accordance with the laws"+
                            "of India." + "</td>" +                          
                            "</tr>" +
                            "<tr>" +
                            "<td style='width: 20%;border: 1px solid;'>" + "<b> Changes in Terms & Conditions</b>" + "</td>" +
                            "<td style='width: 80%;border: 1px solid;'>" + " We reserve the right to modify above terms & conditions at any time without prior notice. It is the"+
                            "buyer's responsibility to review these terms & conditions periodically for updates." + "</td>" +                          
                            "</tr>" +                          
                            "</table>";             
            //Get Party Body part 
            let d = this.ReceivedData.data;
            // let Terms = str.replace("{{Terms}}", TermsCond);
            // console.log(Terms);
            let Details = str.replace("{{PartyDetail}}", PDetail);
            // str = str.replace("{{Partymail}}", this.MsgData.Body);
            // this.DataForm.get("Body").patchValue(str);
  
            let sign = str.replace("{{Signature}}",this.Emailtbl.Emailsign)
            this.DataForm.get("Subject").patchValue(this.MsgData.Subject);
            
            this.salesService.quoteEmail(d.Quote_id,this.r1)
              .subscribe((data: string) => {                      
                this.DataForm.get("Body").patchValue(Details + "<br>" + data + "<br>" + TermsCond + sign +"<br>"+ data1.Body);
              });           
            });
      });    
    }
  
    //Additional Attachments
    onFileSelected(event) {
      let file = event.target.files[0];    
      var at1 = {
        "FileName": file.name,
        "FileType": file.type,
        "RowData": file,
        "Tag": 'N'
      }
      this.NewAtts.push(file);   
      // this.Atts.push(at1);   
    }
  
    //Remove Attached Documents
    removeSelectedFile(i: number,Type: string,name: string){          
      if(Type === 'PA'){      
        this.PartyDocu.splice(i,1);
      }else if(Type === 'QA') {
        this.ReceivedData.Atts.QuotAtt = "";      
        this.Atts.map(x => {
          if(x.FileName === name){
            x.FileName = "";
          }
        });
      }else if(Type === "AT"){
        this.formAtt.splice(i,1);
      }
      else{
        this.NewAtts.splice(i,1);
      }    
    }
  
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 2000,
      });
    }
    createFormGroup() {
      return this.fb.group({
        Email_id: new FormControl(-1),
        Email_to: new FormControl(''), //, [Validators.required, Validators.pattern(/^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]
        Email_cc: new FormControl(''),
        Email_bcc: new FormControl(''),
        Email_from: new FormControl(''),
        Subject: new FormControl(''),
        Body: new FormControl(''),
        Vtype: new FormControl('EN'),
        Refno: new FormControl(this.SrNumber),
        Folder_nm: new FormControl(''),
        UserCtrl: new FormControl('',[Validators.pattern(
          /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
        UserCtrl2: new FormControl(''),
        UserCtrl3: new FormControl(''),
        Attachment: this.fb.array([this.AttachForm()]),
      });
    }
    AttachForm(): FormGroup {
      return this.fb.group({
        Emailatt_id: new FormControl(-1),
        Filename: this.receive.QuotAtt
      });
    }
  
    //Email To
    private _filter(value: string): string[] {
  
      const filterValue = value.toLowerCase();
  
      return this.EmailList.filter(User => User.Vals.toLowerCase().indexOf(filterValue) === 0);
    }
    //Email CC
    private CC_filter(value: string): string[] {
  
      const filterValue = value.toLowerCase();
  
      return this.EmailList.filter(User => User.Vals.toLowerCase().indexOf(filterValue) === 0);
    }
    //Email BCC
    private Bcc_filter(value: string): string[] {
  
      const filterValue = value.toLowerCase();
  
      return this.EmailList.filter(User => User.Vals.toLowerCase().indexOf(filterValue) === 0);
    }

}
