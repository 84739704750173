import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IndenterListComponent } from '../indenter-list/indenter-list.component';
import { PurchaseService } from '../purchase.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-indenter-crud',
  templateUrl: './indenter-crud.component.html',
  styleUrls: ['./indenter-crud.component.css']
})
export class IndenterCrudComponent implements OnInit {

  DataForm: FormGroup;
  loading: boolean = false;
  AddIndForm: FormGroup;
  
  constructor(private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<IndenterListComponent>,
    private PurService: PurchaseService,
    private HardFunc: HardFuncService,
    private Notification: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.AddIndForm = this.IndenterFormGroup();
    //this.GetData();    
    this.loading = true;
    this.DataForm.get("INDT_ID").patchValue(this.ReceivedData.id);
    this.DataForm.get("INDT").patchValue(this.ReceivedData.INDT);
    this.DataForm.get("INDT_NM").patchValue(this.ReceivedData.INDT_NM.trim());
    this.DataForm.get("Username").patchValue(this.ReceivedData.Username);
  }

  SaveData(){
    console.log(this.DataForm.value);
    this.loading = true;
    this.PurService.IndeterSave(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.Notification.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.HardFunc.getError(err);
          console.log(err);
          this.Notification.warn(errstr);
          this.loading = false;
        }
      );
  }
  AddIndenter(){
    console.log(this.AddIndForm.value);
    this.loading = true;
    this.PurService.AddIndenter(this.AddIndForm.value)
      .subscribe(data => {
          this.loading = false;
          this.Notification.success(' Data Saved Successfully...');
      },
      err => {
        let errstr = this.HardFunc.getError(err);
        this.Notification.warn(errstr);
        this.loading =false;
      }      
      );
  }
  createFormGroup() {
    return this.fb.group({
      INDT_ID: new FormControl(-1),
      INDT: new FormControl(''),
      INDT_NM: new FormControl(''),
      Username: new FormControl(''),
    });
  }

  IndenterFormGroup() {
    return this.fb.group({
      INDT_ID: new FormControl(-1),
      INDT: new FormControl(''),
      INDT_NM: new FormControl(''),
      Username: new FormControl(''),
    });
  }

}
