import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment'
import { P } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})

export class PurchaseService {
  getheaders;

  constructor(private hardfunc: HardFuncService,
              private http: HttpClient) {
                this.getheaders = new HttpHeaders({
                  'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
                })
               }

  getDatas(data: any) {
    //console.log(Dept);
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Option = "D";
    // data.Fdate = Fdate;
    // data.Tdate = Tdate;
    data.Vtype = "P";
    // data.Cond = Dept;
    let url = this.hardfunc.BaseAPIUrl+"api/PurchaseAPI/PrChkList1"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  PurBySrno(Srno: number){
    var data: any = {};
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Srno = Srno;
    let url = this.hardfunc.BaseAPIUrl+"api/PurchaseBySrno/PurbySrno"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }


  getIndentList(data: any) {

    //var data: any = {};
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/indentsAPI/Indents"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  SaveData(data:any, Slip_no) {
    console.log(Slip_no);
    data.Slip_no = Slip_no;
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Purpose1 = data.Purpose1;
    let url = this.hardfunc.BaseAPIUrl+"api/indentsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  GetIndentById(Indent_id: number){
    console.log(Indent_id);
    let url = this.hardfunc.BaseAPIUrl+"api/indentsAPI/GetDatabyId?id="+Indent_id 
    return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
  }
  Delete_note(Indent_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/DeleteData?id=" + Indent_id;
		return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  getIndenterList(){
    var data: any = {};
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/IndentersAPI/Indenters"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }

  GetIndenterById(INDT_ID: number,data: any){
    data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
    console.log(INDT_ID);
    let url = this.hardfunc.BaseAPIUrl+"api/IndentersAPI/GetDatabyId?id="+INDT_ID 
    return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
  }
  IndeterSave(data:any) {
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/IndentersAPI/SaveIndenter"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  AddIndenter(data: any){
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/IndentersAPI/SaveIndenter"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  DeleteIndenter(INDT_ID: number) {
    let url = this.hardfunc.BaseAPIUrl+"api/IndentersAPI/DeleteData?id="+INDT_ID;
        return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
  }
  IndApprove(Indent_id: number, Qnty1: number, Aqnty: number,Tag: string){
    let data: any = {};
    data.Indent_id = Indent_id;
    data.Qnty1 = Qnty1;
    data.Aqnty = Aqnty;
    data.Tag = Tag;
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/IndentApprove";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  retehelp(Product_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/RateHelp?Product_id="+Product_id;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetPoreg(data: any){            
    data.Cond = data.Cond;
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/PoregAPI/Getdata";
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  getPartyWiseInd(data: any){
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.PARTY_NAME = data.partyname;
    let url = this.hardfunc.BaseAPIUrl + "api/PoregAPI/Getdata";
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  IndDelete(Indent_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/DeleteData?id="+Indent_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  // GetOrderList(){
  //   let url = this.hardfunc.BaseAPIUrl + ""
  //   return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  // }
  PurOrder(pono: number){
    let data: any = {
      Firm: this.hardfunc.Firmx,
      Yrx: this.hardfunc.Yrx,
      Po_no: pono,
    }
    let url = this.hardfunc.BaseAPIUrl + "api/PurchaseAPI/PORDR"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  PurOrder1(PONO: number){
    let data: any = {
      Firm: this.hardfunc.Firmx,
      Yrx: this.hardfunc.Yrx,
      Po_no: PONO,
    }
    let url = this.hardfunc.BaseAPIUrl + "api/PurchaseAPI/PORDR1"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  POSave(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  POPartySave(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveJwOut(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/JwReportAPI/SaveJwOut"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetChallan(Entryno: number){
    let data: any = {
      Firmx: this.hardfunc.Firmx,
      Entryno: Entryno,      
    }
    let url = this.hardfunc.BaseAPIUrl + "api/JwReportAPI/GetChallan"
    return this.http.post(url, data, { headers:  this.hardfunc.PostHeaders() });
  }
  jw_tranbyTran_id(TRAN_ID: number){
    let data: any = {
      Firmx: this.hardfunc.Firmx,
      Entryno: TRAN_ID,      
    }
    let url = this.hardfunc.BaseAPIUrl + "api/JwReportAPI/jw_tranbyTran_id"
    return this.http.post(url, data, { headers:  this.hardfunc.PostHeaders() });
  }
  GetIndent(MpiNo: number){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/IndentList?Slip_no=" + MpiNo
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  IndentDelete(id){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/DeleteData?id=" + id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  IndApproved(){
    let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/IndApproved"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  PoPartyList(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDataParty(Party_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatabyId?id=" + Party_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveDataImpo(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ImportProduct"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  EditImpoPrd(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/UpdImpPrd"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetAllProduct(){
    let data: any = {};    
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductList"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  ProductListbyParty(id: number){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/ProductListbyParty?Party_id=" + id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  ImPoDetailsbyPk_id(id: number){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/ImPoDetailsbyPk_id?Party_id=" + id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetPOList(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetAllImpPrd(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetAllImpPrd"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  DownPoPDF(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/CreatePOPdf"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDatabyId(pk_id: number) {		
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetDatabyId?id="+pk_id;
		return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
	}
  GetLastPono(){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetLastImpoNo?Year=" + this.hardfunc.Yrx
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  DeletePO(Pk_id: number){ //+ "&Revno=" + Revno
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/DeleteData?id=" + Pk_id 
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }   
  UpdProdFam(Subcode_id: number,Vals: string){
    let url = this.hardfunc.BaseAPIUrl + "api/Common/CodemastsAPI/UpdPrdFamily?Subcode_id=" + Subcode_id + "&Vals=" + Vals
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  ImpPrdList(){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetImpProduct"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  ImpEditItem(Product_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetDatabyImp1?id=" + Product_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  PartyPoItems(Pmst_cod: string){       
    let cnd = " a.pmst_cod = '"+Pmst_cod+"'  AND a.pqnty > 0 "
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/PartyPoItems?firmx=" + this.hardfunc.Firmx + "&cnd=" + cnd
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDatabyImp1(Pmst_cod: string){
    console.log(Pmst_cod);
    let cnd = " a.Itcode = '"+Pmst_cod+"'  AND a.pqnty > 0 "
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/ImpPoItems?firmx=" + this.hardfunc.Firmx + "&cnd=" + cnd
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveImpGrn(data: any){
    let url = this.hardfunc.BaseAPIUrl+"api/Store/grnsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  ImpGrnList(Grnno: number){    
    let data: any = {};
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Grnnox = Grnno;
    let url = this.hardfunc.BaseAPIUrl + "api/Store/grnsAPI/Grnnew"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  ImpGrnRecord(Grnno: number){    
    let data: any = {};
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Grnnox = Grnno;
    let url = this.hardfunc.BaseAPIUrl + "api/Store/grnsAPI/grnRecord"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  PartyPoList(Party_id: any){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetPonoByp_id?Party_id=" + Party_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveImpPay(data: any){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/SaveImpPay"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  DelImpPay(Pk_id: number){
    console.log(Pk_id);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/DelImpPay?id=" + Pk_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
}