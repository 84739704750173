import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PendordComponent } from './pendord/pendord.component';
import { SalesRoutes } from './sales.routing';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { materialize } from 'rxjs/operators';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule, MatIconModule, MatCardModule, MatPaginatorModule,MatMenuModule, MatProgressBarModule, MatButtonModule, MatListModule, MatSidenavModule, MatGridListModule, MatTooltipModule, MatToolbarModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatExpansionModule, MatDialogModule, MatTabsModule, MatTableModule, MatSortModule, MatRadioModule ,MatSlideToggleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OrderInvComponent } from './order-inv/order-inv.component';
import { InvviewerComponent } from './invviewer/invviewer.component';
import { OrderComponent } from './order/order.component';
import { ReturnComponent } from './return/return.component';
import { EnqListComponent } from './Enq/Enq-list.component';
import { EnqCrudComponent } from './Enq/Enq-crud.component';
import { OrdDetComponent } from './ord-det/ord-det.component';
import { QuillModule } from 'ngx-quill';
import { GeneralModule } from '../general/general.module';
import { OsComponent } from './os/os.component';
import { AcgrpComponent } from './acgrp/acgrp.component';
import { EnqreportsComponent } from './enqreports/enqreports.component';
import { TemplateDrivenComponent } from './template-driven/template-driven.component';
//import { MatTableExporterModule } from 'mat-table-exporter';
import { PdcListComponent } from './pdc/Pdc-list.component';
import { PdcCrudComponent } from './pdc/Pdc-crud.component';
import { PdcManComponent } from './pdc/pdc-man.component';
import { PdcDashComponent } from './pdc/pdc-dash/pdc-dash.component';
import { MY_DATE_FORMATS } from '../shared/mydateformat';
import { DialogComponent } from './pdc/dialog/dialog.component';
import { AppDateAdapter } from '../shared/mydateformat';
import { AuthGuard } from 'src/app/Services/auth/auth.guard';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PdcEditComponent } from './pdc/pdc-edit/pdc-edit.component';
import { EmdComponent } from './emd/emd.component';
import { MatFormFieldModule, MatBadgeModule } from '@angular/material';
import { BgComponent } from './bg/bg.component';
import { BankGuaranteeComponent } from './bg/bank-guarantee/bank-guarantee.component';
import { BgListComponent } from './bg/bg-list/bg-list.component';
import { EmdListComponent } from './emd/emd-list/emd-list.component';
import { EmdManComponent } from './emd/emd-man/emd-man.component';
import { EnquiryComponent } from './Enq/enquiry/enquiry.component';
import { EnqComponent } from './Enq/enq/enq.component';
import { BgUpdateComponent } from './bg/bg-update/bg-update.component';
import { PendordDialogComponent } from './pendord/pendord-dialog/pendord-dialog.component';
import { OsCrudComponent } from './os/os-crud/os-crud.component';
import { OS1Component } from './os1/os1.component';
import { PendordAttachComponent } from './pendord/pendord-attach/pendord-attach.component';
import { PendordAddfileComponent } from './pendord/pendord-addfile/pendord-addfile.component';
import { BgAttachmentComponent } from './bg/bg-attachment/bg-attachment.component';
import { FDRComponent } from './fdr/fdr.component';
import { FdrManComponent } from './fdr/fdr-man/fdr-man.component';
import { FdrListComponent } from './fdr/fdr-list/fdr-list.component';
import { FacTransCrudComponent } from './fac-trans-crud/fac-trans-crud.component';
import { FactransListComponent } from './fac-trans-crud/factrans-list/factrans-list.component';
import { FactransManComponent } from './fac-trans-crud/factrans-man/factrans-man.component';
import { StoreProdComponent } from './store-prod/store-prod.component';
import { StoreManComponent } from './store-prod/store-man/store-man.component';
import { ProductListComponent } from './store-prod/product-list/product-list.component';
import { GlTransComponent } from './gl-trans/gl-trans.component';
import { GlTranCrudComponent } from './gl-trans/gl-tran-crud/gl-tran-crud.component';
import { SalesMoniterComponent } from './sales-moniter/sales-moniter.component';
import { PartyOsComponent } from './os1/party-os/party-os.component';
import { EditNoteComponent } from './os1/edit-note/edit-note.component';
import { EmdEditnoteComponent } from './emd/emd-editnote/emd-editnote.component';
import { BgEditnoteComponent } from './bg/bg-editnote/bg-editnote.component';
import { OsdetailComponent } from './osdetail/osdetail.component';
import { UserDashboardComponent } from './Enq/user-dashboard/user-dashboard.component';
import { SafeHtmlPipe } from 'src/app/Pipes/common/safehtml.pipe';
import { EnqpartydetailComponent } from './Enq/enqpartydetail/enqpartydetail.component';
import { Sorder1EditComponent } from './os1/sorder1-edit/sorder1-edit.component';
import { CrComponent } from './cr/cr.component';
import { QuoteComponent } from './quote/quote.component';
import { QuoteCrudComponent } from './quote-crud/quote-crud.component';
import { QuoteListComponent } from './quote-crud/quote-list/quote-list.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { QuoteMailComponent } from './quote/quote-mail/quote-mail.component';
import { EnqHelpComponent } from './enq-help/enq-help.component';
import { PackingListComponent } from './packing-list/packing-list.component';
import { QuoteHistoryComponent } from './quote-history/quote-history.component';
import { SalesStatisticsComponent } from './sales-statistics/sales-statistics.component';
import { RejectionMngComponent } from './rejection-mng/rejection-mng.component';
import { RejEntryComponent } from './rejection-mng/rej-entry/rej-entry.component';
import { RejListComponent } from './rejection-mng/rej-list/rej-list.component';
import { CrEntryComponent } from './cr-entry/cr-entry.component';
import { CrListComponent } from './cr-list/cr-list.component';
import { RcMastComponent } from './cr/rc-mast/rc-mast.component';
import { RcListComponent } from './cr/rc-list/rc-list.component';
import { OldArcComponent } from './cr/old-arc/old-arc.component';
import { SoAddFltrComponent } from './pendord/so-add-fltr/so-add-fltr.component';
import { CrMailComponent } from './cr/cr-mail/cr-mail.component';

const Primeng = [
  TableModule,
 // SliderModule,
 // DropdownModule,
//  MultiSelectModule,
 // GrowlModule,
 // FileUploadModule
]

const Material = [
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDialogModule,
  MatCardModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatBadgeModule
]


@NgModule({
  declarations: [PendordComponent, 
    PdfviewerComponent,
    OrderInvComponent,
    InvviewerComponent,
    EnqListComponent,
    OrderComponent,
    EnqCrudComponent,
    ReturnComponent,
    OrdDetComponent,
    OsComponent,
    AcgrpComponent,
    EnqreportsComponent,
    TemplateDrivenComponent,
    PdcListComponent,
    PdcCrudComponent,
    PdcManComponent,
    PdcDashComponent,
    DialogComponent,
    PdcEditComponent,
    EmdComponent,
    BgComponent,
    BankGuaranteeComponent,
    BgListComponent,
    EmdListComponent,
    EmdManComponent,
    EnquiryComponent,
    EnqComponent,
    BgUpdateComponent,
    PendordDialogComponent,
    OsCrudComponent,
    OS1Component,
    PendordAttachComponent,
    PendordAddfileComponent,
    BgAttachmentComponent,
    FDRComponent,
    FdrManComponent,
    FdrListComponent,
    FacTransCrudComponent,
    FactransListComponent,
    FactransManComponent,
    OsdetailComponent,
    StoreProdComponent,
    StoreManComponent,
    ProductListComponent,
    GlTransComponent,
    GlTranCrudComponent,
    SalesMoniterComponent,
    PartyOsComponent,
    EditNoteComponent,
    EmdEditnoteComponent,
    BgEditnoteComponent,
    UserDashboardComponent,
    SafeHtmlPipe,
    EnqpartydetailComponent,
    Sorder1EditComponent,
    CrComponent,
    QuoteComponent,
    QuoteCrudComponent,
    QuoteListComponent,
    QuoteMailComponent,
    EnqHelpComponent,
    PackingListComponent,
    QuoteHistoryComponent,
    SalesStatisticsComponent,
    RejectionMngComponent,
    RejEntryComponent,
    RejListComponent,
    CrEntryComponent,
    CrListComponent,
    RcMastComponent,
    RcListComponent,
    OldArcComponent,
    SoAddFltrComponent,
    CrMailComponent,

    
    ],
  imports: [
    CommonModule,
    FormsModule,
    GeneralModule,
    ReactiveFormsModule,
    Material,
    Primeng,
    Ng2SearchPipeModule,
    GeneralModule,
    PdfViewerModule,
    QuillModule,
    //MatTableExporterModule,
    MatPaginatorModule,
    MatFormFieldModule,
    QuillModule.forRoot(),
    MatSortModule,
    CKEditorModule,
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
      FormsModule,
      ReactiveFormsModule,
    RouterModule.forChild(SalesRoutes),
    
  ],
  providers:[
    {provide: MAT_DATE_LOCALE, useValue: 'en-IN'},
    { provide: DateAdapter, useClass: AppDateAdapter },
    AuthGuard
  ],
  entryComponents: [
    OrderInvComponent,
    EnqCrudComponent,
    PdcCrudComponent,
    DialogComponent,
    OsdetailComponent,
    PendordDialogComponent,
    PendordAttachComponent,
    PendordAddfileComponent,
    BgAttachmentComponent,
    FacTransCrudComponent,
    GlTranCrudComponent,
    StoreProdComponent,
    PartyOsComponent,
    EditNoteComponent,
    EmdEditnoteComponent,
    BgEditnoteComponent,
    EnqListComponent,
    EnqpartydetailComponent,
    Sorder1EditComponent,
    QuoteComponent,
    QuoteMailComponent,
    EnqHelpComponent,
    QuoteHistoryComponent,
    CrEntryComponent,
    RcMastComponent,
    SoAddFltrComponent,
    CrMailComponent,
    ],
})
export class SalesModule { }